.portfolio__pro, .portfolio__perso {
    text-align: center;
    color: var(--color-primary);
    background: var(--color-primary-variant);
    line-height: 4rem;
    width: 75%;
    margin: 3rem auto;
    border: solid 1px transparent;
    border-radius: 0.5rem;
}

.portfolio__perso {
    margin: 6rem auto 3rem;
}

.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__logo {
    padding: 0.1rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    width: 2rem;
    margin-right: 0.5rem;
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    position: relative;
}

.portfolio__item h3 {
    display: flex;
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}


.portfolio__item-image:hover .overlay {
    visibility: visible;
    opacity: 1;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
}

.overlay p {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.overlay ul {
    /*list-style: none;*/
    /*margin: 0;*/
    /*padding: 0;*/
}

.overlay ul li {
    margin-bottom: 0.3rem;
}

.overlay ul li:before {
    content: "-";
    margin-right: 0.3rem;
}

.overlay__content {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    color: black;
    opacity: 0.8;
    transition: opacity 0.9s ease-in-out;
    padding: 4rem;
    transform: translateY(-50px);
}

.overlay__content-baseline {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: white;
    color: black;
    opacity: 0.8;
    transition: opacity 0.9s ease-in-out;
    padding: 1rem;
    font-size: 1rem;
}

.overlay__content-baseline-title {
    text-transform: uppercase;
}

.overlay__content-baseline-text {
    padding: 0.3rem 2rem 0.3rem 1rem;
    text-align: justify;
}

.overlay__content-stack-title {
    text-transform: uppercase;
}

.overlay__content-stack {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    top: 65%;
    left: 0;
    width: 100%;
    height: 35%;
    background-color: white;
    color: black;
    opacity: 0.8;
    transition: opacity 0.9s ease-in-out;
    padding: 1rem;
    font-size: 1rem;
}

.overlay__content-stack-list {
    list-style: inherit;
    padding: 0.3rem 2rem;
}



/* ================ MEDIA QUERIES (MEDIUM DEVICES) ======================= */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    .overlay__content-baseline {
        top: 0;
        height: 60%;
        padding: 1rem;
        font-size: 0.6rem;
    }

    .overlay__content-stack {
        top: 60%;
        height: 40%;
        padding: 1rem;
        font-size: 0.6rem;
    }

    .overlay__content-stack-list {
        padding: 0.3rem 1rem;
    }

}

/* ================ MEDIA QUERIES (SMALL DEVICES) ======================= */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .overlay__content-baseline {
        top: 0;
        height: 60%;
        padding: 1rem;
        font-size: 0.7rem;
    }

    .overlay__content-stack {
        top: 60%;
        height: 40%;
        padding: 1rem;
        font-size: 0.7rem;
    }

    .overlay__content-stack-list {
        padding: 0.3rem 1rem;
    }

}
